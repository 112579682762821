<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- User Profile-->
        <li>
            <div class="user-profile d-flex no-block m-t-20">
                <div class="user-pic">
                    <img src="assets/images/users/1.jpg" alt="users" class="rounded-circle" width="40" />
                </div>
                <div class="dropdown user-content hide-menu m-l-10" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle href="javascript:void(0)" class="dropdown-toggle" id="Userdd" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <h5 class="m-b-0 user-name font-medium">{{user_name}}
                            <i class="fa fa-angle-down"></i>
                        </h5>
                        <span class="op-5 user-email">{{user_email}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="Userdd" ngbDropdownMenu>
                       
                        <a class="dropdown-item" [routerLink]="['/authentication/logout']">
                            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                    </div>
                </div>
            </div>
        </li>
		
		 <li>
            <div class="user-profile d-flex no-block m-t-20">
                <div class="user-pic">
					<form #searchform = "ngForm" (ngSubmit) = "onClickSubmit(searchform.value)"> 
						<input type="text" placeholder="Search" name="search" class="form-control float-left col-lg-9" ngModel />
						<button type="submit" class="btn btn-secondary btn-circle"><i class="fa fa-search"></i></button>
					</form>	
                </div>
                
            </div>
        </li>
        
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true"><i [ngClass]="[sidebarnavItem.icon]"></i><span class="hide-menu">{{sidebarnavItem.title}}</span></div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink && sidebarnavItem.user_type.includes(user_type)" (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;" (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse Second-level" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;" [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>