import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'starter',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      { path: 'cards', loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule) },
      { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
      { path: 'forms', loadChildren: () => import('./form/forms.module').then(m => m.FormModule) },
      { path: 'tables', loadChildren: () => import('./table/tables.module').then(m => m.TablesModule) },
      { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartModule) },
      {
        path: 'widgets',
        loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
      },
      { path: 'ecom', loadChildren: () => import('./ecommerce/ecom.module').then(m => m.EcomModule) },
      {
        path: 'timeline',
        loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
      },
      {
        path: 'extra-component',
        loadChildren:
          () => import('./extra-component/extra-component.module').then(m => m.ExtraComponentModule)
      },
      { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
      { path: 'apps/email', loadChildren: () => import('./apps/email/mail.module').then(m => m.MailModule) },
      { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
      {
        path: 'sample-pages',
        loadChildren: () => import('./sample-pages/sample-pages.module').then(m => m.SamplePagesModule)
      },
	  { path: 'sector', loadChildren: () => import('./sector/sector.module').then(m => m.SectorModule) },
	  { path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule) },
	  { path: 'reasoncode', loadChildren: () => import('./reasoncode/reasoncode.module').then(m => m.ReasoncodeModule) },
	  { path: 'tariffclass', loadChildren: () => import('./tariffclass/tariffclass.module').then(m => m.TariffclassModule) },
	  { path: 'agency', loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule) },
	  { path: 'bills', loadChildren: () => import('./bills/bills.module').then(m => m.BillsModule) },
	  { path: 'batchbills', loadChildren: () => import('./batchbills/batchbills.module').then(m => m.BatchbillsModule) },
	  { path: 'flags', loadChildren: () => import('./flags/flags.module').then(m => m.FlagsModule) },
	  { path: 'tariffrate', loadChildren: () => import('./tariffrate/tariffrate.module').then(m => m.TariffrateModule) },
	  { path: 'editrates', loadChildren: () => import('./editrates/editrates.module').then(m => m.EditratesModule) },
	  { path: 'billscan', loadChildren: () => import('./billscan/billscan.module').then(m => m.BillscanModule) },
	  { path: 'billreports', loadChildren: () => import('./billreports/billreports.module').then(m => m.BillReportsModule) },
	  { path: 'verifybill', loadChildren: () => import('./verifybill/verifybill.module').then(m => m.VerifybillModule) },
	  { path: 'batch', loadChildren: () => import('./batch/batch.module').then(m => m.BatchModule) },
	  { path: 'summery', loadChildren: () => import('./summery/summery.module').then(m => m.SummeryModule) },
	  { path: 'inspectbill', loadChildren: () => import('./inspectbill/inspectbill.module').then(m => m.InspectbillModule) },
	  { path: 'admininspectbill', loadChildren: () => import('./admininspectbill/admininspectbill.module').then(m => m.AdminInspectbillModule) },
	  { path: 'user', loadChildren: () => import('./users/user.module').then(m => m.UserModule) },
	  { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
