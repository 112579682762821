import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    
    {
        path: '/dashboard',
        title: 'Dashboards',
        icon: 'mdi mdi-view-dashboard',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['admin']
    },
	{
        path: '/organization/index',
        title: 'Organization',
        icon: 'mdi mdi-phone',
        class: 'has-arrow',
        extralink: false,
        submenu: [
			{
				path: '/organization/index',
				title: 'Organization Type',
				icon: 'mdi mdi-phone',
				class: '',
				extralink: false,
				submenu: [],
				user_type: ['admin']
			},
			{
				path: '/sector/index',
				title: 'Sectors',
				icon: 'mdi mdi-phone',
				class: '',
				extralink: false,
				submenu: [],
				user_type: ['admin']
			}
		],
		user_type: ['admin']
    },
	{
        path: '',
        title: 'Agencies',
        icon: 'mdi mdi-view-dashboard',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/agency/index',
                title: 'Manage Agencies',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin','reviewer']
            }
           
        ],
		user_type: ['admin','reviewer']
    },
	{
        path: '',
        title: 'Bills',
        icon: 'mdi mdi-view-dashboard',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/bills/list',
                title: 'Manage Bills',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin','readonly']
            },
			{
                path: '/bills/sethigh',
                title: 'Set High Reading',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin','readonly']
            },{
                path: '/bills/rejected',
                title: 'Rejected Bills',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin','readonly']
            }
           
        ],
		user_type: ['admin','readonly']
    },
	
	{
        path: '/reasoncode/index',
        title: 'Reason Code',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['admin']
    },
	{
        path: '',
        title: 'Tariff',
        icon: 'mdi mdi-view-dashboard',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/tariffclass/index',
                title: 'Tariff Class',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin']
            },
            {
                path: '/tariffrate/index',
                title: 'Tariff Rate',
                icon: 'mdi mdi-adjust',
                class: '',
                extralink: false,
                submenu: [],
				user_type: ['admin']
            },
           
        ],
		user_type: 'admin'
    },
	{
        path: '/billscan',
        title: 'Scan Bill',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['inputer']
    },
	{
        path: '/billreports',
        title: 'Reports',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['admin','readonly']
    },
	{
        path: '/admininspectbill',
        title: 'Inspections',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['admin']
    },
	
	{
        path: '/batch',
        title: 'Bills Batches',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['reviewer']
    },
	{
        path: '/inspectbill',
        title: 'Inspect Bills',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['inspector']
    }
	,
	{
        path: '/user',
        title: 'Users',
        icon: 'mdi mdi-phone',
        class: '',
        extralink: false,
        submenu: [],
		user_type: ['admin']
    }
];
